<template>
  <div class="document">
    <div class="page-header">
      <h1>{{ $t('Document Management') }} > {{ $t('Document') }} > {{ $t('Add') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Add Document') }}</h2>
      </div>
      <DocumentForm @action="addDocument"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Document from '@/lib/document';
import DocumentForm from '@/components/document/DocumentForm.vue';

export default {
  name: 'CreateDocument',
  components: {
    DocumentForm
  },
  methods:{
    async addDocument(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const document = await Document.createDocument(this.apiUrl, form, loginInfo);
        this.$router.push('/documents');
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
